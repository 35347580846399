<template>
  <div class="footer">
    <p>
      © 2024 MidGrnd. All rights reserved.
      <router-link to="/about">Privacy Policy</router-link>
      <span class="divider"> | </span>
      <router-link to="/about">Terms of Use</router-link>
      <span class="divider"> | </span>
      <router-link to="/about">Contact Us</router-link>
    </p>
    <p>This website is currently in development phase.</p>
  </div>
</template>

<script lang="ts">
export default {
  name: "PageFooter",
};
</script>

<style lang="scss">
@media screen and (min-width: 600px) {
  .footer {
    text-align: center;
    padding: 20px;
    /* Add padding to the footer */
    background-color: #f0f0f0;
    /* Background color for the footer */
    margin-top: 20px;
    /* Add some space between the content and the footer */
  }

  .footer p {
    margin: 0;
    /* Remove default margin */
  }
}
@media screen and (max-width: 600px) {
  .footer {
    text-align: center;
    padding: 20px;
    /* Add padding to the footer */
    background-color: #f0f0f0;
    /* Background color for the footer */
    margin-top: 300px;
    /* Add some space between the content and the footer */
  }

  .footer p {
    margin: 0;
    /* Remove default margin */
  }
}
</style>
