<template>
  <router-view />
</template>

<script>
// Stop error ResizeObserver
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
