<template>
  <div class="logo">
    <img
      alt="MidGrnd logo"
      :src="require('@/assets/logo.png')"
      class="logo-image"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppLogo",
});
</script>

<style lang="scss">
.logo-image {
  display: block;
  margin: 0 auto;
  max-width: 100%; /* Ensure image does not exceed container width */
  margin-bottom: 20px;
}

/* Media query for small screens */
@media (max-width: 600px) {
  .logo-image {
    width: 60%; /* Adjust as needed */
    left: 20%;
  }
}

/* Media query for large screens */
@media (min-width: 600px) {
  .logo-image {
    width: 30%; /* Adjust as needed */
  }
}
</style>
