<template>
  <div class="search-container">
    <h2>Search Results</h2>
    <div class="input-row">
      <label
        for="address1"
        style="font-weight: bold; margin-right: 10px; white-space: nowrap"
        >Address 1:</label
      >
      <span id="address1">{{ address1 }}</span>
    </div>
    <div class="input-row">
      <label
        for="address2"
        style="font-weight: bold; margin-right: 10px; white-space: nowrap"
        >Address 2:</label
      >
      <span id="address2">{{ address2 }}</span>
    </div>
    <div class="input-row">
      <label for="category" style="font-weight: bold; margin-right: 10px"
        >Category:</label
      >
      <input
        id="category"
        type="text"
        v-model="category"
        placeholder="category.value"
        style="margin-right: 10px"
      />
      <button @click="update">Update</button>
    </div>
  </div>
  <div id="map-container" class="map-container">
    <div id="my-map"></div>
  </div>
  <div class="results-container">
    <div v-for="(location, index) in places" :key="index" class="location-card">
      <div class="location-info">
        <h3>
          {{ location.name }}
          <a
            v-if="location.website"
            :href="location.website"
            target="_blank"
            class="website-link"
          >
            Visit website</a
          >
        </h3>
        <p><strong>Address: </strong>{{ location.address }}</p>
        <p v-if="location.operating_hours">
          <strong>Hours:</strong> {{ location.operating_hours }}
        </p>
        <p v-if="location.contact">
          <strong>Contact Info: </strong>{{ location.contact.phone }}
        </p>
        <button class="copy-button" @click="copyAddress(location.address)">
          Copy Address
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { postData } from "@/utils/guestSearch"; // Import the postData function
import maplibregl from "maplibre-gl";
import { onMounted, ref } from "vue";

export default {
  name: "ResultView",
  setup() {
    const address1 = ref("");
    const address2 = ref("");
    const category = ref("");
    function showLoadingOverlay() {
      // Create overlay container
      const overlay = document.createElement("div");
      overlay.classList.add("loading-overlay");

      // Create spinner element
      const spinner = document.createElement("div");
      spinner.classList.add("spinner");

      // Create comment element
      const comment = document.createElement("div");
      comment.classList.add("comment");

      // Add spinner and comment to overlay
      overlay.appendChild(spinner);
      overlay.appendChild(comment);

      // Append overlay to the body
      document.body.appendChild(overlay);

      // Array of switching comments
      const comments = ["Searching the new category...", "Almost there..."];

      // Switch comments every second
      let index = 0;
      const commentInterval = setInterval(() => {
        comment.textContent = comments[index];
        index = (index + 1) % comments.length;
      }, 1500);

      // Clear interval when overlay is removed
      overlay.addEventListener("DOMNodeRemoved", () => {
        clearInterval(commentInterval);
      });
    }

    // Remove the loading overlay
    function hideLoadingOverlay() {
      const overlay = document.querySelector(".loading-overlay");
      if (overlay) {
        overlay.remove();
      }
    }
    function update() {
      showLoadingOverlay();
      // Call postData function with the values of address1, address2, and category
      postData({
        addresses: [address1.value, address2.value],
        category: category.value,
      }).then(() => {
        hideLoadingOverlay();
      });
    }
    function getZoomLevel(bounds) {
      const maxLon = Math.max(bounds.lon1, bounds.lon2);
      const minLon = Math.min(bounds.lon1, bounds.lon2);
      const maxLat = Math.max(bounds.lat1, bounds.lat2);
      const minLat = Math.min(bounds.lat1, bounds.lat2);

      // Calculate horizontal and vertical bounds
      const horizontalBound = maxLon - minLon;
      const verticalBound = maxLat - minLat;

      // Calculate zoom level based on the bounds
      const padding = 80; // Padding in pixels
      const boundsAspectRatio = horizontalBound / verticalBound;
      const mapContainer = document.getElementById("my-map");
      const mapWidth = mapContainer.offsetWidth - padding;
      const mapHeight = mapContainer.offsetHeight - padding;
      const mapAspectRatio = mapWidth / mapHeight;

      if (boundsAspectRatio > mapAspectRatio) {
        return Math.floor(
          Math.log2(280 * (mapWidth / (256 * horizontalBound)))
        );
      } else {
        return Math.floor(Math.log2(180 * (mapHeight / (256 * verticalBound))));
      }
    }

    const myMap = ref(null);
    const places = ref([]);
    const constructedObject = ref(null);
    let currentPopup = null;
    let coordinates = null;

    onMounted(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const constructedObjectQueryParam = urlParams.get("constructedObject");
      const searchParams = urlParams.get("postData");
      let searchParamsValue = {};
      if (searchParams) {
        searchParamsValue = JSON.parse(decodeURIComponent(searchParams));
      }
      if (
        searchParamsValue.addresses &&
        searchParamsValue.addresses.length > 0
      ) {
        address1.value = searchParamsValue.addresses[0];
        address2.value = searchParamsValue.addresses[1];
      }

      if (searchParamsValue.category) {
        category.value = searchParamsValue.category;
      }

      function highlightCard(index) {
        document.querySelectorAll(".location-card").forEach((card, i) => {
          if (index !== null) {
            if (i !== index) {
              // Apply styles to non-selected cards
              card.style.height = "30px"; // Adjust width as needed
              card.style.overflow = "hidden";
              card.style.backgroundColor = "#f3f3f3"; // Gray background color
            } else {
              // Apply styles to the selected card
              card.style.height = "140px"; // Default width for selected card
              card.style.backgroundColor = ""; // Reset background color
            }
          } else {
            // Reset styles for all cards if index is null
            card.style.width = "140px"; // Default width
            card.style.backgroundColor = ""; // Reset background color
          }
        });
      }
      function getPopupOffset() {
        console.log(window.innerWidth);
        // Calculate the offset based on screen size
        if (window.innerWidth <= 600) {
          return [0, -400]; // Adjust as needed for smaller screens
        } else {
          return [0, -450]; // Adjust as needed for larger screens
        }
      }
      if (constructedObjectQueryParam) {
        constructedObject.value = JSON.parse(
          decodeURIComponent(constructedObjectQueryParam)
        );
      }

      const apiKey = process.env.VUE_APP_GEOAPIFY_API_KEY;
      const bounds = constructedObject.value.map_bound;
      const type = constructedObject.value.category;
      const locations = constructedObject.value.places;
      var map = new maplibregl.Map({
        center: [
          (bounds.lon1 + bounds.lon2) / 2,
          (bounds.lat1 + bounds.lat2) / 2,
        ],
        zoom: getZoomLevel(bounds),
        container: "my-map",
        style: `https://maps.geoapify.com/v1/styles/klokantech-basic/style.json?apiKey=${apiKey}`,
      });

      const geoJsonFeatures = [];
      // Iterate through each location and convert it to a GeoJSON feature
      locations.forEach((location) => {
        const { name, address, lat, lon, website, operating_hours, contact } =
          location;

        // Create a GeoJSON feature object for each location
        const feature = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [lon, lat],
          },
          properties: {
            name: name,
            address: address,
          },
        };

        // Push the GeoJSON feature to the array
        geoJsonFeatures.push(feature);
        places.value.push({
          name: name,
          address: address,
          website: website,
          operating_hours: operating_hours,
          contact: contact,
        });
      });

      map.on("load", () => {
        var layerId = `${type}-layer`;
        if (map.getSource(type)) {
          map.removeLayer(layerId);
          map.removeSource(type);
        }
        // Initialize an empty array to store GeoJSON features
        map.addSource(type, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: geoJsonFeatures,
          },
        });

        map.addLayer({
          id: layerId,
          type: "circle",
          source: type,
          paint: {
            "circle-color": "#4264fb",
            "circle-radius": 8,
            "circle-stroke-width": 2,
            "circle-stroke-color": "#ffffff",
          },
        });

        map.on("click", layerId, function (e) {
          var coordinates = e.features[0].geometry.coordinates.slice();
          var name = e.features[0].properties.name;

          highlightCard(
            places.value.findIndex((location) => location.name === name)
          );
          var popupContent = `
              <div class="popup" style="background-color: rgba(255, 255, 255, 0.85); padding: 10px; border-radius: 10px;">
                <div style="font-family: Arial, sans-serif; font-size: 14px; font-weight: bold; margin-bottom: 5px;">${name}</div>
              </div>
            `;

          const popupOffset = getPopupOffset();

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          currentPopup = new maplibregl.Popup({
            anchor: "bottom",
            offset: popupOffset,
            closeButton: false,
          })
            .setLngLat(coordinates)
            .setText(name)
            .setHTML(popupContent)
            .addTo(map);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on("mouseenter", layerId, function () {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change it back to a pointer when it leaves.
        map.on("mouseleave", layerId, function () {
          map.getCanvas().style.cursor = "";
        });
      });

      document.body.addEventListener("click", function (event) {
        const clickedInfoContainer = event.target.closest(".location-info");
        if (clickedInfoContainer) {
          const index = Array.from(
            clickedInfoContainer.parentElement.parentElement.children
          ).indexOf(clickedInfoContainer.parentElement);
          highlightCard(index);
          if (currentPopup) {
            currentPopup.remove();
            currentPopup = null;
          }

          const name_selected = places.value[index].name;
          locations.forEach((location) => {
            const { name, lat, lon } = location;
            if (name == name_selected) {
              coordinates = [lon, lat];
            }
          });
          // You can use the coordinates here to show the corresponding popup on the map
          var popupContent = `
              <div class="popup" style="background-color: rgba(255, 255, 255, 0.85); padding: 10px; border-radius: 10px;">
                <div style="font-family: Arial, sans-serif; font-size: 14px; font-weight: bold; margin-bottom: 5px;">${name_selected}</div>
              </div>
            `;
          const popupOffset = getPopupOffset();
          currentPopup = new maplibregl.Popup({
            anchor: "bottom",
            offset: popupOffset,
            closeButton: false,
          })
            .setLngLat(coordinates)
            .setHTML(name_selected)
            .setHTML(popupContent)
            .addTo(map);
        }
      });
    });
    const copyAddress = (address) => {
      // Create a textarea element to hold the address temporarily
      const textarea = document.createElement("textarea");
      textarea.value = address;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      // Optionally, provide feedback to the user that the address has been copied
      alert("Address successfully copied!");
    };
    return {
      myMap,
      places,
      copyAddress,
      address1,
      address2,
      category,
      update,
    };
  },
};
</script>

<style lang="scss">
@media screen and (min-width: 600px) {
  .search-container {
    position: absolute;
    top: 10px; /* Adjust top position as needed */
    left: 10px; /* Adjust left position as needed */
    z-index: 10; /* Higher z-index to be above other containers */
  }
  .search-container button {
    background-color: #16c172;
    font-size: 12px;
    width: 20%;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  .input-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .map-container {
    position: absolute;
    top: calc(20% + 80px);
    left: 10px;
    width: 100%;
    height: 60%;
  }

  #my-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }

  .results-container {
    position: absolute;
    margin-left: 20;
    margin-top: 20;
    top: calc(20% + 80px);
    left: 52%;
    height: 60%;
    width: 50%;
    overflow-y: auto; /* Enable vertical scrolling if overflow occurs */
  }

  .location-card {
    width: 200;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 5px;
    box-sizing: border-box;
    /* Include padding and border in the width */
    height: 140px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }
  .search-container {
    position: absolute;
    top: 10px; /* Adjust top position as needed */
    left: 10px; /* Adjust left position as needed */
    z-index: 10; /* Higher z-index to be above other containers */
  }
  .search-container h2 {
    font-size: medium;
  }
  .search-container label {
    font-size: 0.5em;
  }
  .search-container span {
    font-size: 0.5em;
  }
  .search-container input {
    height: 12px;
  }
  .search-container button {
    background-color: #16c172;
    font-size: 10px;
    width: 20%;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  .input-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .map-container {
    position: absolute; /* Change position to relative */
    top: calc(20% + 20px);
    width: 100%;
    height: 50%; /* Adjust height as needed */
    z-index: 1;
  }

  #my-map {
    position: absolute;
    width: 100%; /* Adjust width to fill the container */
    height: 100%; /* Adjust height to fill the container */
  }

  .results-container {
    position: absolute; /* Change position to relative */
    top: calc(80% + 70px);
    width: 100%;
    height: 50%; /* Adjust height as needed */
    overflow-y: auto; /* Enable vertical scrolling if needed */
    z-index: 0;
  }

  .location-card {
    width: 200;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 5px;
    box-sizing: border-box;
    /* Include padding and border in the width */
    height: 140px;
    overflow-y: auto;
  }
}
.location-info {
  cursor: default;
  flex-direction: column;
  display: flex;
}

.website-link {
  font-size: 0.5em;
  cursor: pointer;
}
.location-info p,
.location-info h3 {
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

.copy-button {
  margin-left: auto;
  background-color: transparent;
  border: none;
  color: #4264fb;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.copy-button:hover {
  background-color: #4264fb; // Change background color on hover
  color: #ffffff; // Change text color on hover
}
</style>
