import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "results" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Result = _resolveComponent("Result")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createElementVNode("img", {
        alt: "MidGrnd logo",
        src: require('@/assets/logo.png'),
        class: "logo-image-result"
      }, null, 8, _hoisted_1),
      _createVNode(_component_router_link, {
        to: "/",
        class: "router-link"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Return to Search")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Result)
        ])
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}