<template>
  <div class="home">
    <Logo />
    <GuestSearch />
    <Footer />
    <!-- Place the Footer component within the main content -->
  </div>
</template>

<script lang="ts">
import Footer from "@/components/Footer.vue";
import GuestSearch from "@/components/GuestSearch.vue";
import Logo from "@/components/Logo.vue";

import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    Logo,
    Footer,
    GuestSearch,
  },
})
export default class HomeView extends Vue {}
</script>
