<template>
  <div class="guest-search">
    <div class="input-group">
      <div :id="autocompleteContainer1Id"></div>
      <div class="error-banner" v-if="address1Missing">
        <p class="error-message">{{ address1Missing }}</p>
      </div>
      <div class="error-banner" v-if="invalidAddress1">
        <p class="error-message">{{ invalidAddress1 }}</p>
      </div>
    </div>
    <div class="input-group">
      <div :id="autocompleteContainer2Id"></div>
      <div class="error-banner" v-if="address2Missing">
        <p class="error-message">{{ address2Missing }}</p>
      </div>
      <div class="error-banner" v-if="invalidAddress2">
        <p class="error-message">{{ invalidAddress2 }}</p>
      </div>
    </div>
    <div class="input-group">
      <label for="category" style="font-weight: bold"> Category:</label>
      <input
        type=" text"
        id="category"
        v-model="category"
        placeholder="e.g., cafe, restaurant"
      />
      <p style="font-style: italic; margin: 0; text-align: left; color: grey">
        Note: if no category is entered or if the entered category is invalid,
        cafes will be displayed.
      </p>
    </div>
    <button @click="search">Let's Go</button>
  </div>
</template>

<script lang="ts">
import { addressAutocomplete } from "@/utils/addressAutocomplete";
import { postData } from "@/utils/guestSearch"; // Import the postData function
import { Ref, defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "GuestSearch",
  setup() {
    const router = useRouter();
    const category = ref("");
    var address1Value = ref("");
    var address2Value = ref("");
    var address1Missing = ref("");
    var address2Missing = ref("");
    var invalidAddress1 = ref("");
    var invalidAddress2 = ref("");
    const autocompleteContainer1Id = ref(
      `autocomplete-container1-${Math.random().toString(36).substring(7)}`
    );
    const autocompleteContainer2Id = ref(
      `autocomplete-container2-${Math.random().toString(36).substring(7)}`
    );
    // Add a loading overlay to disable the page
    function showLoadingOverlay() {
      // Create overlay container
      const overlay = document.createElement("div");
      overlay.classList.add("loading-overlay");

      // Create spinner element
      const spinner = document.createElement("div");
      spinner.classList.add("spinner");

      // Create comment element
      const comment = document.createElement("div");
      comment.classList.add("comment");

      // Add spinner and comment to overlay
      overlay.appendChild(spinner);
      overlay.appendChild(comment);

      // Append overlay to the body
      document.body.appendChild(overlay);

      // Array of switching comments
      const comments = ["Searching the middle...", "Almost there..."];

      // Switch comments every second
      let index = 0;
      const commentInterval = setInterval(() => {
        comment.textContent = comments[index];
        index = (index + 1) % comments.length;
      }, 1500);

      // Clear interval when overlay is removed
      overlay.addEventListener("DOMNodeRemoved", () => {
        clearInterval(commentInterval);
      });
    }

    // Remove the loading overlay
    function hideLoadingOverlay() {
      const overlay = document.querySelector(".loading-overlay");
      if (overlay) {
        overlay.remove();
      }
    }

    const flashErrorMessage = (errorType: string) => {
      // Show the error message
      if (errorType === "address1Missing") {
        address1Missing.value = "Please enter address 1.";
      } else if (errorType === "address2Missing") {
        address2Missing.value = "Please enter address 2.";
      }
      // After 1 second, hide the error message
      setTimeout(() => {
        if (errorType === "address1Missing") {
          address1Missing.value = "";
        } else if (errorType === "address2Missing") {
          address2Missing.value = "";
        }
      }, 1000); // 1000 milliseconds = 1 second
      hideLoadingOverlay();
    };

    const clearInvalidAddresses = () => {
      invalidAddress1.value = "";
      invalidAddress2.value = "";
    };

    const search = async () => {
      clearInvalidAddresses();
      showLoadingOverlay();
      const address1 = document.getElementById(
        autocompleteContainer1Id.value + "-input"
      ) as HTMLInputElement;
      const address2 = document.getElementById(
        autocompleteContainer2Id.value + "-input"
      ) as HTMLInputElement;
      const categoryElement = document.getElementById(
        "category"
      ) as HTMLInputElement | null;
      address1Value.value = address1.value.trim();
      address2Value.value = address2.value.trim();
      // Check if address1 or address2 is empty
      if (!address1Value.value || !address2Value.value) {
        if (!address1Value.value) {
          address1Missing.value = "Please enter address 1.";
          flashErrorMessage("address1Missing");
        }
        if (!address2Value.value) {
          address2Missing.value = "Please enter address 2.";
          flashErrorMessage("address2Missing");
        }
        return;
      }

      if (categoryElement === null || categoryElement.value.trim() === "") {
        category.value = "cafe";
      } else {
        category.value = categoryElement.value;
      }

      const postDataPayload = {
        addresses: [address1Value.value, address2Value.value],
        category: category.value,
      };
      try {
        await postData(postDataPayload, router);
        hideLoadingOverlay();
      } catch (error) {
        if (typeof error === "string") {
          if (error.includes(address1Value.value)) {
            invalidAddress1.value =
              "Address is too generic or incomplete. Please provide a more specific address.";
          }
          if (error.includes(address2Value.value)) {
            invalidAddress2.value =
              "Address is too generic or incomplete. Please provide a more specific address.";
          }
        }
        hideLoadingOverlay();
      }
    };

    const initializeAutocomplete = (
      containerId: Ref<string>,
      inputLabel: string
    ): any => {
      let containerElement = document.getElementById(containerId.value);
      if (!containerElement) {
        // If container element doesn't exist, create it
        containerElement = document.createElement("div");
        containerElement.id = containerId.value;
        document.body.appendChild(containerElement); // Append to body or appropriate parent element
      }

      // Clear the existing container
      containerElement.innerHTML = "";

      if (containerElement) {
        addressAutocomplete(
          containerElement,
          inputLabel,
          (data: any) => {
            console.log(data);
          },
          {
            placeholder: "Enter an address here",
          }
        );
      }
    };
    onMounted(() => {
      initializeAutocomplete(autocompleteContainer1Id, "Address 1");
      initializeAutocomplete(autocompleteContainer2Id, "Address 2");
    });
    return {
      search,
      autocompleteContainer1Id,
      autocompleteContainer2Id,
      category,
      address1Value,
      address2Value,
      address1Missing,
      address2Missing,
      invalidAddress1,
      invalidAddress2,
    };
  },
});
</script>

<style lang="scss">
@media screen and (min-width: 600px) {
  .guest-search {
    position: relative;
    width: 40%;
    left: 30%;
    padding: 10px;
    display: block;
  }

  [id^="autocomplete-container"] {
    margin-bottom: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  [id^="autocomplete-container"] label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .autocomplete-items {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
    border-top: none;
    z-index: 99;
    /* Ensure autocomplete items match the width of the container exactly: */
    width: calc(100% - 22px);
    top: calc(100% + 2px);
    background-color: #fff;
  }

  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
  }

  .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: rgba(0, 0, 0, 0.1);
  }

  .autocomplete-items .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: rgba(0, 0, 0, 0.1);
  }

  .clear-button {
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    position: absolute;
    right: 5px;
    display: none;
    top: 35px;
  }

  .clear-button.visible {
    display: block;
  }

  .clear-button:hover {
    color: rgba(0, 0, 0, 0.6);
  }

  .guest-search label {
    margin-bottom: 5px;
    text-align: left;
  }

  .input-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }

  .input-group input {
    width: calc(100% - 22px);
    /* Allow the input to take up remaining space */
    outline: none;
    border-top: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    font-size: 16px;
    display: flex;
  }

  .guest-search button {
    background-color: #16c172;
    font-size: 20px;
    width: 70%;
    height: 30%;
    border-radius: 12px;
    color: white;
    margin-top: 10px;
  }

  .error-banner {
    margin: 0;
  }

  .error-message {
    margin: 0;
    text-align: left;
  }

  .error-banner p {
    color: chocolate;
  }
}

@media screen and (max-width: 600px) {
  .guest-search {
    position: relative;
    width: 80%;
    left: 10%;
    padding: 10px;
    display: block;
  }

  [id^="autocomplete-container"] {
    margin-bottom: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  [id^="autocomplete-container"] label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .autocomplete-items {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
    border-top: none;
    z-index: 99;
    /* Ensure autocomplete items match the width of the container exactly: */
    width: calc(100% - 22px);
    top: calc(100% + 2px);
    background-color: #fff;
  }

  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
  }

  .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: rgba(0, 0, 0, 0.1);
  }

  .autocomplete-items .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: rgba(0, 0, 0, 0.1);
  }

  .clear-button {
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    position: absolute;
    right: 5px;
    display: none;
    top: 35px;
  }

  .clear-button.visible {
    display: block;
  }

  .clear-button:hover {
    color: rgba(0, 0, 0, 0.6);
  }

  .guest-search label {
    margin-bottom: 5px;
    text-align: left;
  }

  .input-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }

  .input-group input {
    width: calc(100% - 22px);
    /* Allow the input to take up remaining space */
    outline: none;
    border-top: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    font-size: 16px;
    display: flex;
  }

  .guest-search button {
    background-color: #16c172;
    font-size: 15px;
    width: 50%;
    height: 30%;
    border-radius: 12px;
    color: white;
    margin-top: 10px;
  }

  .error-banner {
    margin: 0;
  }

  .error-message {
    margin: 0;
    text-align: left;
  }

  .error-banner p {
    color: chocolate;
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
  z-index: 9999;
  /* Ensure the overlay appears on top of other content */
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  /* Light grey border */
  border-top: 4px solid #ffffff;
  /* White border on top */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  /* Rotate animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.comment {
  color: #ffffff;
  /* White text color */
  font-size: 16px;
  margin-top: 10px;
}
</style>
