import axios, { AxiosResponse } from "axios";
import { Router } from "vue-router";

// Define your API endpoint
const API_URL = process.env.VUE_APP_MIDGRND_API_URL;

interface PostData {
  addresses: string[];
  category: string;
}

interface Place {
  name: string;
  address: string;
  categories: string[];
  lat: number;
  lon: number;
  place_id: string;
  website: string | null;
  operating_hours: any | null;
  contact: any | null;
}

interface Api200Response {
  category: string;
  locations: Place[];
  map_bound: {
    lat1: number;
    lat2: number;
    lon1: number;
    lon2: number;
  };
}

interface Api422Detail {
  detail: string;
  invalid_addresses: object;
}

interface Api422Response {
  message: Api422Detail;
}

interface ConstructedObject {
  category: string;
  places: Place[];
  map_bound: {
    lat1: number;
    lat2: number;
    lon1: number;
    lon2: number;
  };
}

function handle200Response(
  response: AxiosResponse<string>
): Promise<ConstructedObject> {
  return new Promise((resolve, reject) => {
    try {
      const encodedString: string = response.data;
      const decodedString: string = atob(encodedString);
      const apiResponse: Api200Response = JSON.parse(decodedString);
      console.log(apiResponse);
      const places: Place[] = apiResponse.locations.map((location) => ({
        name: location.name,
        address: location.address,
        categories: location.categories,
        lat: location.lat,
        lon: location.lon,
        place_id: location.place_id,
        website: location.website,
        operating_hours: location.operating_hours,
        contact: location.contact,
      }));

      const constructedObject: ConstructedObject = {
        category: apiResponse.category,
        places: places,
        map_bound: apiResponse.map_bound,
      };

      resolve(constructedObject);
    } catch (error) {
      reject(error);
    }
  });
}

function handleErrorResponse(
  response: AxiosResponse<Api422Response>
): Promise<Api422Detail> {
  return new Promise((resolve, reject) => {
    try {
      const apiResponse: Api422Response = response.data;
      const constructedObject = apiResponse.message;
      resolve(constructedObject);
    } catch (error) {
      reject(error);
    }
  });
}

export async function postData(postData: PostData, router: Router) {
  const apiKey = process.env.VUE_APP_MIDGRND_API_KEY;
  const url = `${API_URL}/v1/search`;
  try {
    const response = await axios.post(url, postData, {
      headers: {
        "X-API-Key": apiKey,
        "Content-Type": "application/json",
      },
    });
    const constructedObject = await handle200Response(response);
    const postDataQueryParam = encodeURIComponent(JSON.stringify(postData));
    const constructedObjectQueryParam = encodeURIComponent(
      JSON.stringify(constructedObject)
    );
    window.location.href = `/result?constructedObject=${constructedObjectQueryParam}&postData=${postDataQueryParam}`;
  } catch (error: any) {
    const errorObject = await handleErrorResponse(error.response);
    const invalidAddressKeys = Object.keys(errorObject.invalid_addresses);
    const invalidAddressString: string = invalidAddressKeys.join(", ");
    const errorMessage = invalidAddressString + ": " + errorObject.detail;
    throw errorMessage;
  }
}
