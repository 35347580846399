<template>
  <nav>
    <img
      alt="MidGrnd logo"
      :src="require('@/assets/logo.png')"
      class="logo-image-result"
    />
    <router-link to="/" class="router-link">Return to Search</router-link>
  </nav>
  <div class="container">
    <div class="content">
      <div class="results">
        <Result />
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import Footer from "@/components/Footer.vue";
import Result from "@/components/Result.vue";
import Logo from "@/components/Logo.vue";

import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    Logo,
    Footer,
    Result,
  },
})
export default class HomeView extends Vue {}
</script>

<style lang="scss">
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.router-link {
  margin-left: auto;
  font-weight: bold;
  color: #42b983;
}

.logo-image-result {
  margin-left: 10px;
  width: 8%;
}
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure container takes up at least the full height of the viewport */
  position: relative;
}

.content {
  flex: 1; /* Fill remaining vertical space */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.results {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0; /* Ensure content area can shrink below its intrinsic height */
  flex-grow: 1; /* Allow content area to grow and shrink */
}

.content h2 {
  text-align: left;
}
</style>
